import { datadogLogs } from '@datadog/browser-logs'
import { datadogRum } from '@datadog/browser-rum'
import { windowGlobal } from './window-global'

const allowedEnvs = ['production', 'staging']

const configureDataDogRum = ({
  applicationId,
  clientToken,
  service,
  version,
}) => {
  datadogRum.init({
    applicationId,
    clientToken,
    site: 'datadoghq.com',
    service,
    version,
    sessionSampleRate: 50,
    sessionReplaySampleRate: 20,
    traceSampleRate: 100,
    defaultPrivacyLevel: 'mask-user-input',
    trackUserInteractions: true,
    trackFrustrations: true,
    trackResources: true,
    trackLongTasks: true,
    enableExperimentalFeatures: ['clickmap'],
  })

  datadogRum.startSessionReplayRecording()
}

const configureDataDogLogs = ({ clientToken, service, version }) => {
  datadogLogs.init({
    clientToken,
    version,
    service,
    site: 'datadoghq.com',
    forwardErrorsToLogs: true,
    sampleRate: 100,
  })

  datadogLogs.logger.addContext('host', windowGlobal()?.location?.host)
}

const startMonitoring = () => {
  const appEnv = process.env.DATADOG_ENV ?? ''
  const applicationId = process.env.DATADOG_APPLICATION_ID ?? ''
  const clientToken = process.env.DATADOG_CLIENT_TOKEN ?? ''
  const version = process.env.GITHUB_SHA ?? ''
  const service = process.env.DATADOG_SERVICE ?? ''

  const config = {
    applicationId,
    clientToken,
    version,
    service,
  }

  if (allowedEnvs.includes(appEnv)) {
    configureDataDogLogs(config)
    configureDataDogRum(config)
  }
}

export { startMonitoring }
